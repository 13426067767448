<template>
  <div class="">
    <el-card class="box-card">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div class="inputbox" style="display: flex; align-items: center">
          <div style="display: flex; align-items: center">
            <div class="team">请选择团队</div>
            <el-select
              clearable
              v-loading="teamDataLoading"
              v-model="value"
              @change="change"
              placeholder="请选择"
            >
              <el-option
                v-for="item in teamData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <!-- <div style="display: flex; align-items: center; margin-left: 30px">
            <div class="team">请选择模板</div>
            <el-select
              clearable 
              v-loading="teamDataLoading"
              v-model="value2"
              placeholder="请选择"
            >
              <el-option
                v-for="item in templateData"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div> -->
          <div style="display: flex; align-items: center; margin-left: 30px">
            <div class="title">已定义模板</div>
            <el-input
              placeholder="请输入已定义模板名称"
              class="input-with-select"
              :clearable="true"
              v-model="keyword"
              @keyup.enter.native="search"
            >
            </el-input>
            <el-button
              style="margin-left: 20px"
              type="primary"
              size="small"
              @click="search"
              >查询</el-button
            >
            <el-button type="primary" size="small" @click="resetData"
              >重置</el-button
            >
          </div>
        </div>

        <div>
          <el-button size="small" @click="createNewTemplate" type="primary"
            >创建新模板</el-button
          >
        </div>
      </div>
      <div style="margin-top: 20px" v-if="tableData && tableData.length > 0">
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          default-expand-all
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column prop="title" label="模板名称"> </el-table-column>
          <el-table-column prop="user_nickname" label="创建人">
          </el-table-column>
          <el-table-column prop="group_name" label="团队名称">
          </el-table-column>
          <el-table-column prop="create_date" label="创建时间">
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column prop="valid" label="是否有效" align="center">
            <template slot-scope="scope">
              <el-switch
              :disabled="userInfo.id!=scope.row.user_id"
                v-model="scope.row.valid"
                :active-value="1"
                :inactive-value="2"
                @change="setValid(scope.row, $event)"
              />
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >查看模板详细</el-button
              >
              <el-button
                @click="editNewTemplate(scope.row)"
                type="text"
                :disabled="userInfo.id!=scope.row.user_id"
                size="small"
                >编辑</el-button
              >
              <el-button
                @click="templateTransfer(scope.row)"
                type="text"
                :disabled="userInfo.id!=scope.row.user_id"
                size="small"
                >模板转让</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="tableData.length == 0">
        <el-empty description="暂无数据,请选择团队"></el-empty>
      </div>
    </el-card>

    <!-- 创建新模板 -->
    <el-dialog title="创建新模板" :visible.sync="dialogFormVisible">
      <el-form :model="form" v-loading="editLoading">
        <template>
          <div style="display: flex;">
            <el-form-item label="模板名称：" style="width: 45%;" :label-width="formLabelWidth">
              <el-input v-model="form.title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="模板备注：" style="width: 45%;"  :label-width="formLabelWidth">
              <el-input v-model="form.remark" autocomplete="off"></el-input>
            </el-form-item>
          </div>
        </template>
        <div style="display: flex;">
          <el-form-item label="团队名称：" style="width: 45%;"  :label-width="formLabelWidth">
             
              <el-input  @change="searchTeam" clearable placeholder="请输入团队名称"  v-model="teamkeyword" @keyup.enter.native="searchTeam" autocomplete="off"></el-input>
          </el-form-item>
          <el-button size="mini" style="height: 30px;margin-left: 20px;" type="primary" @click="searchTeam">搜 索</el-button>
        </div>
        <el-tree
          :data="templateData"
          show-checkbox
          v-loading="templateDataLoading"

          default-expand-all
          node-key="id"
          ref="templateTree"
          highlight-current
          @check="handleCheck"
          :default-checked-keys="defaultChecked"
          :props="defaultProps">
        </el-tree>
        <!-- <el-table
          ref="tree"
          :data="templateData"
          v-loading="templateDataLoading"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          default-expand-all
          @row-click="rowClick"
          @select="handleSelection" 
          @select-all="selectAll"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="name" label="团队名称"> </el-table-column>
        </el-table> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="saveTemplate"
          >保 存</el-button
        >
      </div>
    </el-dialog>
    <!-- 模板详情 -->
    <el-dialog title="模板详情" :visible.sync="templateDialog">
      <el-tree
        :data="templateDetailsData"
        show-checkbox
        default-expand-all
        node-key="id"
        ref="templateTree"
        highlight-current
        @check-change="handleCheck"
        :default-checked-keys="defaultChecked"
        :props="defaultProps">
      </el-tree>
      <!-- <el-table
        ref="templateDataDetails"
        v-loading="dataDetailsLoading"
        :data="templateDetailsData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="name" label="团队名称"> </el-table-column>
      </el-table> -->
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="templateDialog = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
    <!-- 模板转让 -->
    <el-dialog title="模板转让" width="26%" :visible.sync="transferDialog">
      <div style="display: flex; align-items: center;">
            <div class="team">请选择转让人</div>
            <el-select
              clearable
              v-loading="teamDataLoading"
              v-model="userIdvalue"
              @change="changeUser"
              placeholder="请选择"
            >
              <el-option
                v-for="item in userData"
                :key="item.user_id"
                :label="item.realname"
                :value="item.user_id"
              >
              </el-option>
            </el-select>
          </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="transferDialog = false"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="confirmTransfer"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      tableData: [],
      tableLoading: false,
      templateDialog: false,
      dataDetailsLoading: false,
      editLoading: false,
      value: "",
      value2: "",
      userIdvalue: "",
      teamData: [],
      templateData: [],
      templateDataLoading:false,
      templateDetailsData: [],
      teamDataLoading: false,
      groupId: 0, //下拉框的团队id
      formLabelWidth: "120px",
      form: {
        title: "",
        remark: "",
      },
      dialogFormVisible: false,
      transferDialog:false,
      keyword: "",
      teamkeyword: "",
      templateId: 0, //模板id
      userData:[],
      defaultProps: {
          children: 'children',
          label: 'name'
        },
        defaultChecked:[],//选中的
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getMyTeam();
    this.getTemplateList();
  },
  //方法集合
  methods: {
   

  
    // 团队树
    getTeamTree() {
      this.templateDataLoading = true;

      this.$http
        .post("/xapi/user.group/teamFocusTree", { id: this.groupId,keyword: this.teamkeyword })
        .then((res) => {
          this.templateDataLoading = false;

          this.templateData = res.data;
          console.log(this.tableData, "ressssssss");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 团队树搜索
    searchTeam(){
      this.templateDataLoading=true
      if(this.templateId>0){
        this.$http
        .post("/xapi/user.group/teamFocusTemplateDetail", {
          keyword: this.teamkeyword,
          id: this.templateId,
        })
        .then((res) => {
          console.log(res, "查看模板详情");
          // this.groupId = 0;
          this.templateDataLoading = false;
          this.templateData = res.data.tree;
        })
        .catch((err) => {
          console.log(err);
        });
      }else{
        this.getTeamTree();
      }
      
    },
    // 我参加的团队
    getMyTeam() {
      this.teamDataLoading = true;
      this.$http
        .post("/api/v1_0_0.user_group", { my: "1", page: 1, page_size: 99 })
        .then((res) => {
          this.teamDataLoading = false;
          this.teamData = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 下拉框选择
    change(e) {
      console.log(e, "eee");
      // if(!e)return this.tableData=[]
      this.groupId = e;
      this.getTemplateList();
    },
    // 选择转让人
    changeUser(e){
      console.log(e,"ee")
      this.userId=e
    },
    // 根据团队生成模板
    getTemplateList() {
      this.tableLoading = true;
      this.$http
        .post("/xapi/user.group/teamFocusTemplateList", {
          group_id: this.groupId,
          page: 1,
          page_size: 99,
          keyword: this.keyword,
        })
        .then((res) => {
          this.tableLoading = false;
          console.log(res, "resssssss");
          this.tableData = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 创建新模板
    createNewTemplate() {
      console.log(this.templateId,"this.templateId")
      console.log(this.groupId,"this.groupId")
      if (!this.groupId) return this.$message.error("创建新模板请选择团队！");
      this.form = {};
      this.templateId = 0;
      this.getTeamTree();
      this.dialogFormVisible = true;
    },
    // 保存
    saveTemplate() {
      let data = {
        group_id: this.TemplategroupId || this.groupId,
        id: this.templateId,
        title: this.form.title,
        remark: this.form.remark,
        focus: this.focusIds,
      };
      console.log(data, "dataaaa");
      // return
      this.$http
        .post(
          this.templateId
            ? "/xapi/user.group/teamFocusTemplateUpdate"
            : "/xapi/user.group/teamFocusTemplateCreate",
          data
        )
        .then((res) => {
          this.$message.success(this.templateId ? "编辑成功！" : "创建成功！");
          this.dialogFormVisible = false;
          this.groupId = 0;
          this.getTemplateList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 编辑模板 查看详情
    editNewTemplate(e) {
      this.templateId = e.id;
      this.TemplategroupId = e.group_id;
      // console.log(this.templateId, "this.templateId");
      this.dialogFormVisible = true;
      this.editLoading = true;
      this.$http
        .post("/xapi/user.group/teamFocusTemplateDetail", {
        keyword: this.teamkeyword,
          id: this.templateId,
        })
        .then((res) => {
          // console.log(res, "查看模板详情");
          // this.groupId = 0;
          this.editLoading = false;
          this.form = res.data;
          this.templateData = res.data.tree;
          this.defaultChecked=this.collectCheckedFields(this.templateData).map(v=>v.id)
          console.log(this.collectCheckedFields(this.templateData),"collectCheckedFields(this.templateDetailsData)")
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 模板转让弹框
    templateTransfer(e){
      this.groupId=e.group_id
      this.templateId=e.id
      this.transferDialog=true
      this.$http
        .post(
          `xapi/user.group/memberList?page=1`+
            "&pageSize=99" +
            "&team_id=" +
            this.groupId +
            "&type=0"
        )
        .then((res) => {
          this.userData=res.data.data
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    // 确定转让
    confirmTransfer(){
      this.$http
        .post("/xapi/user.group/teamFocusTemplateTransfer", {
          ids: this.templateId,
          user_id: this.userId,
        })
        .then((res) => {
          this.$message.success('转让成功！')
          this.transferDialog=false
          this.getTemplateList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 是否有效
    setValid(row, val) {
      console.log(row, "row,");
      console.log(val, "val,");

      this.$confirm(
        `是否将该模板设置为${val == "1" ? "有效" : "无效"}`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          let data = {
            id: row.id,
            valid: val,
          };
          console.log(data, "dataaaaa");
          this.$http
            .post("/xapi/user.group/teamFocusTemplateSetValid",data)
            .then((res) => {
              this.$message({
                type: "success",
                message: "设置成功！",
              });
            })
            .catch((err) => {
              console.log(err, "err");
              if (val == 2) {
                row.valid = 1;
              } else {
                row.valid = 2;
              }
            });
        })
        .catch(() => {
          console.log("取消发布");
          if (val == 2) {
            row.valid = 1;
          } else {
            row.valid = 2;
          }
        });
    },
      // 将返回数据内isFocus 为true的组成一个数组
    collectCheckedFields(tree) {
      let result = [];
      function  collect(nodes) {
        nodes.forEach(node => {
          if (node.is_focus) {
            result.push(node);
          }
          if (node.children && node.children.length > 0) {
            collect(node.children);
          }
        });
      }
      collect(tree);
      return result;
    },

    // 
    handleCheck(a, b,c){
      console.log(a,b,c)
      this.focusIds = b.checkedKeys;
      console.log(this.focusIds, "this.focusIds");
    },
    // 查看是否勾选
    // setCheckedStatus2(data) {
    //   data.forEach((item) => {
    //     // console.log(item, "item");
    //     if (item.is_focus) {
    //       this.$refs.templateDataDetails.toggleRowSelection(item, true);
    //     }
    //     if (item.children && item.children.length) {
    //       this.setCheckedStatus2(item.children);
    //     }
    //   });
    // },
    // 勾选
    handleSelectionChange(e) {
      console.log(e,"eeeee")
      this.focusIds = e.map((v) => v.id);
      // console.log(this.focusIds, "this.focusIds");
    },
    // 搜索
    search() {
      this.getTemplateList();
    },
    // 查看模板详情
    handleClick(e) {
      this.templateId = e.id;
      this.dataDetailsLoading = true;
      this.templateDialog = true;
      this.$http
        .post("/xapi/user.group/teamFocusTemplateDetail", {
          id: this.templateId,
        })
        .then((res) => {
          this.templateDetailsData = res.data.tree;
          this.dataDetailsLoading = false;
          console.log(this.templateDetailsData,"详情~~~~~~~~~~")
          // collectCheckedFields(this.templateDetailsData)
          this.defaultChecked=this.collectCheckedFields(this.templateDetailsData).map(v=>v.id)
          console.log(this.collectCheckedFields(this.templateDetailsData),"collectCheckedFields(this.templateDetailsData)")
          // this.$nextTick(function () {
          //   this.setCheckedStatus2(this.templateDetailsData);
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 重置
    resetData() {
      this.keyword = "";
      this.getTemplateList();
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style  lang='less' scoped>
.title {
  width: 150px;
  margin-right: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
}
.team {
  min-width: 60px;
  margin-right: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
}
::v-deep {
  .inputbox:nth-child(1) {
    .el-input__inner {
      // border-radius: 40px 0 0 40px;
      // border-radius: 40px;
      height: 32px;
    }
    .el-input-group__append {
      // border-radius: 0 40px 40px 0;
      background: #00957e;
      color: #ffffff;
    }
  }
}
</style>